import React, { useState } from 'react';
import './App.css'; // Import CSS file for styling
import StackedBarChart from './StackedBarChart';
import ScatterChart from './ScatterChart';
import HorizontalHistogram from './HorizontalHistogram';
import './Results.css';
import { FaChevronDown, FaChevronRight, FaCheck, FaTimes, FaBullseye } from 'react-icons/fa'; // Import necessary icons

const TaskTable = ({ tasks, tree }) => {

  console.log('tasks:', tasks);
  console.log('tree:', tree);

  const processTaskData = (taskData) => {
    const results = taskData.results || []; // Provide a default empty array if results is undefined
    const totalResults = results.length;
    const directSuccess = taskData.directSuccess || 0; // Provide default values if these fields are undefined
    const indirectSuccess = taskData.indirectSuccess || 0;
    const directFail = taskData.directFail || 0;
    const indirectFail = taskData.indirectFail || 0;
    const successPercentage = totalResults > 0 ? ((directSuccess + indirectSuccess) / totalResults) * 100 : 0;
    const directSuccessPercentage = totalResults > 0 ? (directSuccess / totalResults) * 100 : 0;
    const indirectSuccessPercentage = totalResults > 0 ? (indirectSuccess / totalResults) * 100 : 0;
    const directFailPercentage = totalResults > 0 ? (directFail / totalResults) * 100 : 0;
    const indirectFailPercentage = totalResults > 0 ? (indirectFail / totalResults) * 100 : 0;

    return {
      totalResults,
      successPercentage,
      directSuccessPercentage,
      indirectSuccessPercentage,
      directFailPercentage,
      indirectFailPercentage,
      results,
    };
  };

  const [expandedTasks, setExpandedTasks] = useState({});

  const toggleTaskExpansion = (taskId) => {
    setExpandedTasks((prev) => ({
      ...prev,
      [taskId]: !prev[taskId],
    }));
  };

  const getNameById = (id, items) => {
    for (const item of items) {
      if (item.id === id) {
        return item.name;
      }
    }
    return null; // return null if the ID is not found
  };

  function getFirstExpectedItem(data, taskId) {
    console.log(data, taskId)
    // Check if the taskId exists in the data object
    if (data[taskId] && data[taskId].results && data[taskId].results.length > 0) {
      // Get the first respondent's result object
      const firstRespondentResult = data[taskId].results[0];
  
      // Check if the first respondent result has an 'expected' array and it contains at least one item
      if (firstRespondentResult.expected && firstRespondentResult.expected.length > 0) {
        // Return the first item in the 'expected' array
        return firstRespondentResult.expected[0];
      }
    }
  
    // Return null or any appropriate value if conditions are not met
    return null;
  }

  const getStringAfterLastDot = (inputString) => {
    const lastDotIndex = inputString.lastIndexOf('.');
    if (lastDotIndex === -1) {
      // No dot found, return the entire string
      return inputString;
    }
    return inputString.substring(lastDotIndex + 1);
  };

  const getPerformanceLevel = (percentage) => {
    if (percentage >= 80) {
      return { label: 'Excellent', color: 'green' };
    } else if (percentage >= 60) {
      return { label: 'Good', color: 'lightgreen' };
    } else if (percentage >= 40) {
      return { label: 'Average', color: 'yellow' };
    } else if (percentage >= 20) {
      return { label: 'Poor', color: 'orange' };
    } else {
      return { label: 'Very Poor', color: 'red' };
    }
  };

  const taskSummaries = Object.entries(tasks).map(([taskId, taskData]) => {
    const processedData = processTaskData(taskData);
    const performanceLevel = getPerformanceLevel(processedData.successPercentage);

    return {
      id: getStringAfterLastDot(taskId),
      success: processedData.successPercentage,
      direct: processedData.directSuccessPercentage + processedData.directFailPercentage,
      ...processedData,
    };
  });

  const getLastItemHistogram = (results, tree) => {
    const histogramData = {};

    results.forEach((result) => {
      const lastItemId = result.path[result.path.length - 1];
      const lastItemName = getNameById(lastItemId, tree);
      const isSuccess = result.success;

      if (!histogramData[lastItemName]) {
        histogramData[lastItemName] = { count: 0, success: 0, fail: 0 };
      }

      histogramData[lastItemName].count += 1;
      if (isSuccess) {
        histogramData[lastItemName].success += 1;
      } else {
        histogramData[lastItemName].fail += 1;
      }
    });

    // Convert histogram data to an array and sort by count
    const sortedHistogramData = Object.entries(histogramData)
      .map(([name, data]) => ({ name, ...data }))
      .sort((a, b) => b.count - a.count);

    return sortedHistogramData;
  };

  return (
    <div>
      <ScatterChart tasks={taskSummaries} />
      <div className='vertical-spacer-small'></div>

      {Object.entries(tasks).map(([taskId, taskData]) => {
        const processedData = processTaskData(taskData);

        const isExpanded = expandedTasks[taskId];
        const performanceLevel = getPerformanceLevel(processedData.successPercentage);

        return (
          <div key={taskId} className="task-summary" id={`task-${getStringAfterLastDot(taskId)}`}>
            <div onClick={() => toggleTaskExpansion(taskId)} style={{ cursor: 'pointer' }}>
              <table width='100%'>
                <thead>
                  <tr className="task-row">
                    <th width='15%'></th>
                    <th width='15%'></th>
                    <th width='15%'></th>
                    <th width='40%'></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="task-row">
                    <td>
                      {isExpanded ? <FaChevronDown /> : <FaChevronRight />}<result-stat> Task {getStringAfterLastDot(taskId)}</result-stat>
                    </td>
                    <td colSpan={2}>
                      <FaBullseye /> <strong>{getNameById(getFirstExpectedItem(tasks, taskId), tree)}</strong>
                    </td>
                  </tr>
                  <tr className="task-row">
                    <td><result-stat>{processedData.totalResults}</result-stat> completions</td>
                    <td><result-stat>{(processedData.directSuccessPercentage + processedData.directFailPercentage).toFixed(0)}%</result-stat> direct</td>
                    
                    <td>
                      <result-stat>{processedData.successPercentage.toFixed(0)}%</result-stat> success
                      <span className="performance-tag" style={{ backgroundColor: performanceLevel.color }}>
                        {performanceLevel.label}
                      </span>
                    </td>
                    <td>
                      <StackedBarChart section1={processedData.directSuccessPercentage} section2={processedData.indirectSuccessPercentage} section3={processedData.directFailPercentage} section4={processedData.indirectFailPercentage} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {isExpanded && (
              <>
              <div className='vertical-spacer-small'></div>
                <h3>Top Locations</h3>
                <HorizontalHistogram data={getLastItemHistogram(processedData.results, tree)} />
                <div className='vertical-spacer-small'></div>
                <h3>Individual Results</h3>
                <table margin-top="20px" width="100%" cellSpacing={0} cellPadding={6} border={0}>
                  <thead>
                    <tr>
                      <th>Respondent ID</th>
                      <th>Time Taken</th>
                      <th>Direct</th>
                      <th>Success</th>
                      <th>Path Taken</th>
                    </tr>
                  </thead>
                  <tbody>
                    {processedData.results.map((result, index) => (
                      <tr key={index}>
                        <td>{result.respondentId}</td>
                        <td>
                          {result.timer}s
                          {result.timer < 10 && (
                            <span className="speeding-warning-tag">Speeding Warning</span>
                          )}
                        </td>
                        <td>{result.direct ? <FaCheck style={{ color: 'grey' }} /> : <FaTimes style={{ color: 'grey' }} />}</td>
                        <td>{result.success ? <FaCheck style={{ color: 'green' }} /> : <FaTimes style={{ color: 'red' }} />}</td>
                        <td>
                          {result.path.slice(0, -1).map(id => getNameById(id, tree)).join(' > ')} > <span style={{ color: result.success ? 'green' : 'red' }}>
                            <b>{getNameById(result.path.slice(-1)[0], tree)}</b>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TaskTable;
