import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FaUser , FaSignOutAlt } from 'react-icons/fa'; // Import FaUser icon
import './App.css'; // Import CSS file for styling

function Nav() {
  const { isAuthenticated, loginWithRedirect, logout, user} = useAuth0();

  console.log(user);

  return (
    <nav className="navbar">
      <div className="navbar-left">
      	<div className="spacer"/>
        <img src={require("./img/treetester.png")} alt="Tree Tester Logo" className="logo" />
      </div>
      <div className="navbar-right">
        {isAuthenticated ? (
          <>
            <button className="nav-button" onClick={() => console.log('Account')}>
               Account <FaUser className="user-icon"/>
            </button>
            <button className="nav-button" onClick={() => logout({ returnTo: "window.location.origin" })}>Logout <FaSignOutAlt className="user-icon"/></button>
          </>
        ) : (
          <button className="nav-button" onClick={() => loginWithRedirect()}>Login / Sign up</button>
        )}
        <div className="spacer"/>
      </div>
    </nav>
  );
}

export default Nav;

