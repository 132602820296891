import React from 'react';
import './HorizontalHistogram.css';

const HorizontalHistogram = ({ data }) => {
  // Sort and limit the data to the top 10 items
  const sortedData = data
    .sort((a, b) => b.count - a.count)
    .slice(0, 10);

  // Find the maximum count for scaling the bars
  const maxCount = sortedData.length > 0 ? Math.max(...sortedData.map(item => item.count)) : 1;

  // Calculate the total count of all responses
  const totalCount = data.reduce((sum, item) => sum + item.count, 0);

  return (
    <div className="horizontal-histogram">
      {sortedData.map((item, index) => (
        <div key={index} className="histogram-bar">
          <div className="label">
            {item.name}
          </div>
          <div
            className="bar"
            style={{
              width: `${(item.count / maxCount) * 100}%`,
              backgroundColor: item.success > item.fail ? 'green' : 'red',
            }}
          >
            {Math.round(item.count / totalCount*100)}% ({item.count})
          </div>
        </div>
      ))}
    </div>
  );
};

export default HorizontalHistogram;
