import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import TabbedLayout from './TabbedLayout';
import Nav from './Nav';
import Collect from './Collect';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  
  const domain = process.env.REACT_APP_DOMAIN;
  const clientId = process.env.REACT_APP_CLIENT;
  const audience = process.env.REACT_APP_AUDIENCE;
  const scope = process.env.REACT_APP_SCOPE;
  
  //console.log(process.env);
  //console.log("Audience",clientId,audience);
  
  
  return (
    <Router>
      {/* ToastContainer placed here to cover all routes */}
      <ToastContainer position="top-right" />
      <Routes>
          <Route path="/collect"  element={<Collect />} />
          <Route path="/" element={
          <Auth0Provider
            domain={domain}
            clientId={clientId}
            
            useRefreshTokens={true} // Ensure refresh tokens are used
            cacheLocation="localstorage" // Use local storage to handle Safari's restrictions
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: audience,
              scope: scope,
            }}
            
          >
            <Nav />
            <TabbedLayout />
          </Auth0Provider>} 
          />
      </Routes>
    </Router>
    
  );
}

export default App;