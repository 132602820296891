import React from 'react';
import './SplashPage.css'; // Import your CSS file for styling
import { FaCheck, FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';

const SplashPage = () => {
    return (
        <div className="container">
            <section className="hero-section">
                <h1-large>Effortless Tree Testing for Powerful UX Insights</h1-large>
                <table>
                  <td>
                  <p>Tree Tester equips you with everything needed to conduct insightful tree tests, enabling you to discover and address user pain points, drive product improvements, and enhance your team's confidence in information architecture today!</p> <button className="cta-button">Get started for free!</button>
                  </td>
                </table>
            </section>

            <section className="features-section">
                <div className="feature">
                    <img src={require("./img/rainbow-tree-small2.png")} alt="Feature 1" />
                </div>
                <div className="feature">
                    <h2>Diagnose Discoverability</h2>
                    <p>Tree Tester helps you pinpoint where users struggle to find information, ensuring your content is easily discoverable.</p>
                    <h2>Simplify Structure</h2>
                    <p>Simplifies your product's structure by revealing the most intuitive way to organise your content.</p>
                    <h2>Perfect Pathways</h2>
                    <p>Tree Tester helps you perfect user pathways, guiding them effortlessly to their desired destinations.</p>
                </div>
            </section>

            <section className="pricing-section">
  <div className="pricing-intro">
    <h2>Choose a Tree Tester plan to suit your needs</h2>
    <p>Switch your plan at any time. No cancellation fees, no minimum subscription length.</p>
  </div>
  <div className="pricing-cards">
    <div className="pricing-card">
      <div className="pricing-header">
        <div className="pricing-title">Tree Tester</div>
        <div className="pricing-price">Free</div>
      </div>
      <div className="pricing-details">
        <div className="pricing-annual">Start using Tree Tester freely, really!</div>
        <button className="pricing-button">Sign Up</button>
      </div>
      <div className="pricing-description">
        <p>All the tools you need to create, deploy and analyse a tree test in no time at all!</p>
        <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Unlimited tree branches</li>
                        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Run 2 tree test projects simultaneously</li>
                        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Up to 6 tasks per test</li>
                        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Up to 10 respondents per project</li>
                        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Unique URL to share your tests</li>
                        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Full data analysis (by task or by respondent)</li>

                    </ul>
      </div>
    </div>
    <div className="pricing-card">
      <div className="pricing-header">
        <div className="pricing-title">Tree Tester <pro>Pro</pro></div>
        <div className="pricing-price">£20 per user/month</div>
      </div>
      <div className="pricing-details">
        <div className="pricing-annual">Starting at three users, billed annually</div>
        <button className="pricing-button">Sign Up</button>
      </div>
      <div className="pricing-description">
        <p>Unlimited tree tests and advanced features that allow you to customise and scale up your testing.</p>
        <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Unlimited tree branches</li>
                        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Unlimited tree test projects running simultaneously</li>
                        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Unlimited tasks per test</li>
                        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Unlimited respondents</li>
                        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Advanced features (e.g. randomisation, sampling, ratings)</li>
                        <li style={{ marginBottom: '0.5rem' }}><FaCheckCircle style={{ marginRight: '0.5rem' }} />Full analysis and data export</li>

                    </ul>
      </div>
    </div>
  </div>
</section>

            <section className="testimonials-section">
                <h2>Testimonials</h2>
                <div className="testimonial">
                    <img src="testimonial-image-1.jpg" alt="Testimonial 1" />
                    <p>Testimonial text goes here.</p>
                </div>
                <div className="testimonial">
                    <img src="testimonial-image-2.jpg" alt="Testimonial 2" />
                    <p>Testimonial text goes here.</p>
                </div>
            </section>

            <footer>
                <p>&copy; 2024 Tree Tester UX Ltd. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default SplashPage;
