// ScatterChart.js
import React, { useEffect, useRef } from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, annotationPlugin, ChartDataLabels);

const ScatterChart = ({ tasks }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && chartRef.current) {
        chartRef.current.update();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  const data = {
    datasets: tasks.map(task => {
      const name = task.id;
      const performance = task.success;
      const directness = task.direct;
      const performanceLevel = getPerformanceLevel(performance);
      return {
        label: `Task ${task.id}`,
        data: [{ x: performance, y: directness, name: name }],
        backgroundColor: performanceLevel.color,
        hoverRadius: 30,
        radius: 15
      };
    })
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        title: {
          display: true,
          text: 'Task Success (%)',
          font: {
            size: 22,
            weight: 'bold'
          }
        },
        ticks: {
          stepSize: 10
        },
        grid: {
          drawBorder: false
        }
      },
      y: {
        beginAtZero: true,
        max: 100,
        title: {
          display: true,
          text: 'Task Directness (%)',
          font: {
            size: 22,
            weight: 'bold'
          }
        },
        ticks: {
          stepSize: 10
        },
        grid: {
          drawBorder: false
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: context => {
            const { x, y, name } = context.raw;
            return `Task ${name} - Success: ${Math.round(x)}%  Directness: ${Math.round(y)}%`;
          }
        },
        bodyFont: {
          size: 20,
          weight: 'bold'
        },
        titleFont: {
          size: 20,
          weight: 'bold'
        }
      },
      annotation: {
        annotations: {
          xAxisLine: {
            type: 'line',
            xMin: 50,
            xMax: 50,
            borderColor: 'black',
            borderWidth: 2
          },
          yAxisLine: {
            type: 'line',
            yMin: 50,
            yMax: 50,
            borderColor: 'black',
            borderWidth: 2
          },
          topLeftText: {
            type: 'label',
            xValue: 25,
            yValue: 75,
            content: ['Misleading (High Directness / Low Success)'],
            backgroundColor: 'rgba(0,0,0,0)',
            font: {
              size: 14,
              weight: 'bold',
              color: 'black'
            }
          },
          topRightText: {
            type: 'label',
            xValue: 75,
            yValue: 75,
            content: ['Intuitive (High Directness / High Success)'],
            backgroundColor: 'rgba(0,0,0,0)',
            font: {
              size: 14,
              weight: 'bold',
              color: 'black'
            }
          },
          bottomLeftText: {
            type: 'label',
            xValue: 25,
            yValue: 25,
            content: ['Confusing (Low Directness / Low Success)'],
            backgroundColor: 'rgba(0,0,0,0)',
            font: {
              size: 14,
              weight: 'bold',
              color: 'black'
            }
          },
          bottomRightText: {
            type: 'label',
            xValue: 75,
            yValue: 25,
            content: ['Unintuitive (Low Directness / High Success)'],
            backgroundColor: 'rgba(0,0,0,0)',
            font: {
              size: 14,
              weight: 'bold',
              color: 'black'
            }
          }
        }
      },
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold',
          size: 14
        },
        formatter: (value, context) => context.chart.data.datasets[context.datasetIndex].data[0].name
      }
    },
    onClick: (event, elements) => {
        console.log(elements);
      if (elements.length > 0) {
        const element = elements[0];
        const datasetIndex = element.datasetIndex;
        const taskId = datasetIndex+1;
        const taskElement = document.getElementById(`task-${taskId}`);
        if (taskElement) {
          taskElement.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  };

  return <Scatter ref={chartRef} data={data} options={options} />;
};

const getPerformanceLevel = (percentage) => {
  if (percentage >= 80) {
    return { label: 'Excellent', color: 'green' };
  } else if (percentage >= 60) {
    return { label: 'Good', color: 'lightgreen' };
  } else if (percentage >= 40) {
    return { label: 'Average', color: 'yellow' };
  } else if (percentage >= 20) {
    return { label: 'Poor', color: 'orange' };
  } else {
    return { label: 'Very Poor', color: 'red' };
  }
};

export default ScatterChart;
