import React, { useState, useEffect } from 'react';
import './Settings.css'; // Ensure to create and import this CSS file
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';

const API_url = process.env.REACT_APP_API_URL;

function Settings({ projectId, projectName, switchActiveTab, projectUrl }) {

    const [randomiseTaskOrder, setRandomiseTaskOrder] = useState(false);
    const [includeAllTasks, setIncludeAllTasks] = useState(true);
    const [numTasksPerRespondent, setNumTasksPerRespondent] = useState(1);
    const [allowTaskSkipping, setAllowTaskSkipping] = useState(false);
    const [customiseSelectionText, setCustomiseSelectionText] = useState(false);
    const [selectionText, setSelectionText] = useState('');
    const [askRating, setAskRating] = useState('');
    const [ratingText, setRatingText] = useState('');
    const [welcomeText, setWelcomeText] = useState('');
    const [completionText, setCompletionText] = useState('');
    const [showCompletionCode, setShowCompletionCode] = useState(false);
    const [postCompletionLink, setPostCompletionLink] = useState(false);
    const [completionURL, setCompletionURL] = useState('');
    const [onlyRecordIfComplete, setOnlyRecordIfComplete] = useState(false);
    const [anonymousRespondents, setAnonymousRespondents] = useState(false);
    const [collectFirstName, setCollectFirstName] = useState(false);
    const [collectSurname, setCollectSurname] = useState(false);
    const [collectEmail, setCollectEmail] = useState(false);
    const [showFinalizeModal, setShowFinalizeModal] = useState(false);
    const [studyIsLive, setStudyIsLive] = useState(false);
    const [liveUrl, setLiveUrl] = useState('');
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        console.log("Live status updated:", studyIsLive); // This will log the updated state after changes
    }, [studyIsLive]);

    useEffect(() => {
        const fetchSettings = async () => {
            if (!projectId) return;

            try {
                const options = { headers: { Authorization: `Bearer ${await getAccessTokenSilently()}` } };
                const response = await axios.get(`${API_url}/settings?id=${projectId}`, options);
                const data = response.data.settingsData;
                console.log("Data", data);

                // Assuming the response data directly contains the settings object
                setRandomiseTaskOrder(data.randomiseTaskOrder);
                setIncludeAllTasks(data.includeAllTasks);
                setNumTasksPerRespondent(data.numTasksPerRespondent);
                setAllowTaskSkipping(data.allowTaskSkipping);
                setCustomiseSelectionText(data.customiseSelectionText);
                setSelectionText(data.selectionText);
                setWelcomeText(data.welcomeText);
                setCompletionText(data.completionText);
                setShowCompletionCode(data.showCompletionCode);
                setPostCompletionLink(data.postCompletionLink);
                setCompletionURL(data.completionURL);
                setOnlyRecordIfComplete(data.onlyRecordIfComplete);
                setAnonymousRespondents(data.anonymousRespondents);
                setCollectFirstName(data.collectFirstName);
                setCollectSurname(data.collectSurname);
                setCollectEmail(data.collectEmail);
            } catch (error) {
                console.error('Failed to fetch settings:', error);
                toast.error('Error loading settings');
            }
        };

        fetchSettings();
    }, [projectId, getAccessTokenSilently]);

    const handleFinalizeClick = () => {
        setShowFinalizeModal(true);
    };

    const toastPop = (toastMessage) => {
        toast(`${toastMessage}`);
    };

    const confirmFinalise = () => {
        setStudyIsLive(true);
        setLiveUrl(`https://treetester.com/collect?id=${projectUrl}`);
        setShowFinalizeModal(false);
    };

    useEffect(() => {
        if (studyIsLive) {
            handleSaveSettings();
            mapUrlToProject();
        }
    }, [studyIsLive]);

    const sanitizeText = (text) => {
        // Configure DOMPurify to allow <br> and <p> tags
        const cleanText = DOMPurify.sanitize(text, {
            ALLOWED_TAGS: ['br', 'p'], // Allow <br> and <p> tags
            ALLOWED_ATTR: [] // No attributes allowed to keep it simple and secure
        });

        return cleanText;
    };

    const mapUrlToProject = async () => {
        const options = { authorizationParams: { audience: process.env.REACT_APP_AUDIENCE } };
        const token = await getAccessTokenSilently(options);

        axios.post(`${API_url}/tests`,
            { id: projectUrl, projectId },
            { headers: { Authorization: `Bearer ${token}` } }
        )
            .then(response => {
                console.log('Mapped URL to project', response.data);
            })
            .catch(error => {
                console.error('Error mapping URL to project: ', error);
            });
    }

    const saveSettings = async (projectId, settingsData) => {
        const sub = user.sub;
        const options = { authorizationParams: { audience: process.env.REACT_APP_AUDIENCE } };
        const token = await getAccessTokenSilently(options);
        const status = studyIsLive;
        console.log("STATUS TO SEND:", status);
        axios.post(`${API_url}/settings`,
            { sub, projectId, settingsData, studyIsLive },
            { headers: { Authorization: `Bearer ${token}` } }
        )
            .then(response => {
                console.log('Settings saved:', response.data);
                toastPop(`Settings saved`);
            })
            .catch(error => {
                console.error('Error saving settings:', error);
                alert('Failed to save settings');
            });
    }

    const handleSaveSettings = () => {
        const settingsData = {
            randomiseTaskOrder,
            includeAllTasks,
            numTasksPerRespondent: Number(numTasksPerRespondent),
            allowTaskSkipping,
            customiseSelectionText,
            selectionText: sanitizeText(selectionText),
            welcomeText: sanitizeText(welcomeText),
            completionText: sanitizeText(completionText),
            showCompletionCode,
            postCompletionLink,
            completionURL,
            onlyRecordIfComplete,
            anonymousRespondents,
            collectFirstName,
            collectSurname,
            collectEmail,
        };
        saveSettings(projectId, settingsData);
    };

    return (
        <div className="settings">
            <h2>Settings</h2>

            <div className="settings-section">
                <h3>Task Options</h3>
                <div className="setting">
                    <span>Randomise task order</span>
                    <label className="switch">
                        <input type="checkbox" checked={randomiseTaskOrder} onChange={() => setRandomiseTaskOrder(!randomiseTaskOrder)} />
                        <span className="slider round"></span>
                    </label>
                </div>
                {randomiseTaskOrder && (
                    <>
                        <div className="setting">
                            <span>Include all tasks</span>
                            <label className="switch">
                                <input type="checkbox" checked={includeAllTasks} onChange={() => setIncludeAllTasks(!includeAllTasks)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        {!includeAllTasks && (
                            <div className="setting">
                                <span>Number of tasks per respondent</span>
                                <input type="number" value={numTasksPerRespondent} onChange={e => setNumTasksPerRespondent(e.target.value)} />
                            </div>
                        )}
                    </>
                )}
                <div className="setting">
                    <span>Allow task skipping</span>
                    <label className="switch">
                        <input type="checkbox" checked={allowTaskSkipping} onChange={() => setAllowTaskSkipping(!allowTaskSkipping)} />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>


            <div className="settings-section">
                <h3>Welcome Message</h3>
                <textarea placeholder="Welcome page text" value={welcomeText} onChange={e => setWelcomeText(e.target.value)} />
                <h3>Completion Message</h3>
                <textarea placeholder="Completion page text" value={completionText} onChange={e => setCompletionText(e.target.value)} />
            </div>

            <div className="settings-section">
                <h3>Completion Options</h3>
                <div className="setting">
                    <span>Show unique completion code</span>
                    <label className="switch">
                        <input type="checkbox" checked={showCompletionCode} onChange={() => setShowCompletionCode(!showCompletionCode)} />
                        <span className="slider round"></span>
                    </label>
                </div>
                <div className="setting">
                    <span>Post-completion link</span>
                    <label className="switch">
                        <input type="checkbox" checked={postCompletionLink} onChange={() => setPostCompletionLink(!postCompletionLink)} />
                        <span className="slider round"></span>
                    </label>
                    {postCompletionLink && (
                        <input type="text" value={completionURL} onChange={e => setCompletionURL(e.target.value)} />
                    )}
                </div>
                <div className="setting">
                    <span>Only record data if all tasks completed</span>
                    <label className="switch">
                        <input type="checkbox" checked={onlyRecordIfComplete} onChange={() => setOnlyRecordIfComplete(!onlyRecordIfComplete)} />
                        <span className="slider round"></span>
                    </label>
                </div>
            </div>

            <div className="settings-section">
                <h3>Respondent Identification</h3>
                <div className="setting">
                    <span>Anonymous respondents</span>
                    <label className="switch">
                        <input type="checkbox" checked={anonymousRespondents} onChange={() => setAnonymousRespondents(!anonymousRespondents)} />
                        <span className="slider round"></span>
                    </label>
                </div>
                {!anonymousRespondents && (
                    <div className="nested-settings">
                        <div className="setting">
                            <span>Collect respondent first name</span>
                            <label className="switch">
                                <input type="checkbox" checked={collectFirstName} onChange={() => setCollectFirstName(!collectFirstName)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="setting">
                            <span>Collect respondent surname</span>
                            <label className="switch">
                                <input type="checkbox" checked={collectSurname} onChange={() => setCollectSurname(!collectSurname)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="setting">
                            <span>Collect respondent email</span>
                            <label className="switch">
                                <input type="checkbox" checked={collectEmail} onChange={() => setCollectEmail(!collectEmail)} />
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                )}
            </div>
            <div className="vertical-spacer"></div>
            <div className="fixed-bar">
            {studyIsLive && (
                <div className='live-banner'>
                    This study is now live at: <a href={liveUrl}>{liveUrl}</a>
                    <button className='banner-button' onClick={() => window.open(liveUrl, '_blank')}>Preview</button>
                    <button className='banner-button' onClick={() => window.open(`${encodeURIComponent(liveUrl)}`, '_blank')}>Share</button>
                </div> )}
                <button className="save-button" onClick={handleSaveSettings}>Save Settings</button>
                <button className="finalise-button" onClick={handleFinalizeClick}>Save and Launch Tree Test</button>
            </div>

            {/* Finalize Modal */}
            {showFinalizeModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setShowFinalizeModal(false)}>&times;</span>
                        <h2>Confirm Tree Test Launch</h2>
                        <p>Once the tree test is live, some settings cannot be changed as they will impact the integrity of the study results.</p>
                        <button onClick={confirmFinalise}>Confirm</button>
                        <button onClick={() => setShowFinalizeModal(false)}>Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Settings;
