import React from 'react';
import { FaInfoCircle } from 'react-icons/fa'; // Make sure to install react-icons if not already

function InfoIcon() {
  return (
    <div className='info-icon'>
      <FaInfoCircle />
      <span className='tooltip-text'>
        Create a hierarchical tree representing the structure of your website or application. This will be used in subsequent tree tests. You can construct the architecture of your site by adding, renaming, and deleting branches or nodes. Each node represents a different page or section of your site.
      </span>
    </div>
  );
}

export default InfoIcon;
