import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import StackedBarChart from './StackedBarChart';
import './Results.css';
import TaskTable from './TaskTable';
import TaskSummaryTable from './TaskSummaryTable';

const API_url = process.env.REACT_APP_API_URL;

function Results(projectId, projectName, switchActiveTab, projectUrl) {
  const [loading, setLoading] = useState(true);
  const [taskResults, setTaskResults] = useState([]);
  const [groupedResults, setGroupedResults] = useState([]);
  const [summary, setSummary] = useState([]);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [tree, setTree] = useState([{ id: 1, name: 'Root', children: [] }]);


  useEffect(() => {
    console.log("Tree:", tree); // This will log the updated state after changes
  }, [tree]);

  useEffect(() => {
    console.log("Grouped results updated:", groupedResults); // This will log the updated state after changes
  }, [groupedResults]);

  useEffect(() => {
    console.log(summary); // This will log the updated state after changes
  }, [summary]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = { authorizationParams: { audience: process.env.REACT_APP_AUDIENCE } };
        const token = await getAccessTokenSilently(options); // Get token once
        const taskResultsResponse = await axios.get(`${API_url}/test-results?projectId=${projectId.projectId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const fetchedTaskResults = taskResultsResponse.data.testResults;
        setTaskResults(fetchedTaskResults);
        const computedSummary = summarise(fetchedTaskResults);
        const grouped = groupResultsByTaskId(fetchedTaskResults);

        setSummary(computedSummary);
        setGroupedResults(grouped);
        fetchTreeData();
      } catch (error) {
        console.error('Error fetching results:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchTreeData = async () => {
    setLoading(true); // Start loading
    try {
      const options = { authorizationParams: { audience: process.env.REACT_APP_AUDIENCE } };
      const token = await getAccessTokenSilently(options);
      const response = await axios.get(`${API_url}/tree`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { treeId: projectId.projectId }
      });
      const { treeData } = response.data;
      
      //console.log(response.data);
      //const unflattenedTree = unflattenTree(treeData);
      //setTree(unflattenedTree || []);
      setTree(response.data.treeData);
    } catch (error) {
      console.error('Error fetching tree data:', error);
    } finally {
      setLoading(false); // Stop loading whether or not there was an error
    }
  };




  function groupResultsByTaskId(respondents) {
    const groupedResults = {};
  
    respondents.forEach(respondent => {
      respondent.tasks.forEach(task => {
        const taskId = task.id;
        if (!groupedResults[taskId]) {
          groupedResults[taskId] = {
            directSuccess: 0,
            indirectSuccess: 0,
            directFail: 0,
            indirectFail: 0,
            results: []
          };
        }
  
        const result = {
          respondentId: respondent.respondentId,
          timer: task.timer,
          direct: task.direct,
          success: task.success,
          expected: task.expected,
          path: task.path,
          firstclick: task.path[0],
          destination: task.path[task.path.length - 1]
        };
  
        if (task.success && task.direct) {
          groupedResults[taskId].directSuccess++;
        } else if (task.success && !task.direct) {
          groupedResults[taskId].indirectSuccess++;
        } else if (!task.success && task.direct) {
          groupedResults[taskId].directFail++;
        } else {
          groupedResults[taskId].indirectFail++;
        }
  
        groupedResults[taskId].results.push(result);
      });
    });
    return groupedResults;
  }

  function summarise(respondents) {
    let respondentIds = new Set();
    let taskCount = 0;
    let directSuccessCount = 0;
    let indirectSuccessCount = 0;
    let directFailCount = 0;
    let indirectFailCount = 0;
    let totalTime = 0;
  
    respondents.forEach(respondent => {
      respondentIds.add(respondent.respondentId);
      respondent.tasks.forEach(task => {
        taskCount++;
        totalTime += task.timer;
        if (task.direct) {
          if (task.success) {
            directSuccessCount++;
          } else {
            directFailCount++;
          }
        } else {
          if (task.success) {
            indirectSuccessCount++;
          } else {
            indirectFailCount++;
          }
        }
      });
    });
  
    const respondentCount = respondentIds.size;
    const avgTime = taskCount > 0 ? Math.round(totalTime / taskCount) : 0;
  
    return {
      respondentCount,
      resultCount: taskCount,
      directSuccessCount,
      indirectSuccessCount,
      directFailCount,
      indirectFailCount,
      avgTime
    };
  }

  return (
    <div>
      {loading ? (
        <div>Loading results...</div>
      ) : (
        <div>
          <div>
            <h2>Results - {projectId.projectName}</h2>
            <table width='100%'>
              <thead>
                <tr>
                  <th width='15%'></th>
                  <th width='15%'></th>
                  <th width='15%'></th>
                  <th width='15%'></th>
                  <th width='40%'></th>
                </tr>
              </thead>
              <tbody>
                  <tr>
                    <td><h1>{summary.respondentCount}</h1><result-label>Respondents</result-label></td>
                    <td><h1>{summary.resultCount}</h1><result-label>Tasks Completed</result-label></td>
                    <td><h1>{summary.avgTime}s</h1><result-label>Per Task (Avg.)</result-label></td>
                    <td><h1>{Math.round(((summary.directSuccessCount + summary.indirectSuccessCount)/summary.resultCount)*100)}%</h1><result-label>Success Rate</result-label></td>
                    <td>
                    <StackedBarChart section1={summary.directSuccessCount/summary.resultCount*100} section2={summary.indirectSuccessCount/summary.resultCount*100} section3={summary.directFailCount/summary.resultCount*100} section4={summary.indirectFailCount/summary.resultCount*100} />
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <div className='vertical-spacer-small'></div>
        
          <div>
            <h3>Task Results</h3>
            <TaskTable tasks={groupedResults} tree={tree}/>
          </div>
          <div>
            <h2>Participant Results</h2>
            <table>
              <thead>
                <tr>
                  <th>Participant ID</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
    
  );
}

export default Results;
