import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { FaCheck, FaAngleLeft, FaSpinner } from 'react-icons/fa'; // Import necessary icons
import './Collect.css';  // Ensure you have the appropriate CSS for styling

// Custom hook to parse query parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Collect = () => {
  const [settings, setSettings] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [treeData, setTreeData] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [selectedLeaf, setSelectedLeaf] = useState(null);
  const query = useQuery();
  const collectUrl = query.get('id');
  const [taskStarted, setTaskStarted] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [respondentId, setRespondentId] = useState(null);
  const [taskPath, setTaskPath] = useState([]);
  const [usedBack, setUsedBack] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [skippingAllowed, setSkippingAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [completionCode, setCompletionCode] = useState(null);

  const API_url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await axios.get(`${API_url}/collect?id=${collectUrl}`);
        if (response.data) {
          console.log(response);
          setSettings(response.data.settings);
          setProjectId(response.data.settings.id);
          setCompletionCode(response.data.completionCode);
          setWelcomeMessage(response.data.settings.settingsData.welcomeText);
          setTasks(response.data.tasks);
          setTreeData(response.data.tree.treeData);
          setSkippingAllowed(response.data.settings.settingsData.allowTaskSkipping);
          const rootNode = response.data.tree.treeData[0]; // Assuming the root node is the first item
          setBreadcrumbs([{ id: rootNode.id, name: rootNode.name }]);
          setLoading(false);
        }
      } catch (error) {
        console.error('Failed to fetch project data:', error);
        setLoading(false);
      }
    };

    if (collectUrl) {
      fetchAllData();
    }
  }, [collectUrl]);

  useEffect(() => {
    console.log(taskData); // This will log the updated state after changes
  }, [taskData]); // This useEffect runs whenever taskData changes

  useEffect(() => {
    if (taskCompleted) {
      handleSendData();
    }
  }, [taskCompleted]);

  const handleNodeClick = node => {
    setBreadcrumbs(prev => [...prev, { id: node.id, name: node.name }]);
    setTaskPath(prev => [...prev, node.id]); // Add node to path

    if (!node.children || node.children.length === 0) {
      setSelectedLeaf(node);
    }
  };

  const startTasks = () => {
    console.log(settings);
    setTaskStarted(true);
    setStartTime(Date.now()); // Capture the start time
    setTaskPath([]); // Initialize or clear the task path
    setUsedBack(false); // Reset back button usage flag
    setRespondentId(`${Date.now()}`);  // Correct usage to convert timestamp to string; 
  };

  const completeTasks = () => {
    setTaskCompleted(true);
    setCurrentTaskIndex(0); // Reset or handle as needed for repeat actions or review

    // Optionally handle final task data submission or logging here
    console.log(taskData);
  };

  const skipTask = () => {
    const endTime = Date.now();
    const timeTaken = Math.round((endTime - startTime) / 1000); // Time in seconds, rounded to the nearest integer

    const currentTask = tasks[currentTaskIndex];
    const isSuccess = null;

    if (currentTaskIndex < tasks.length - 1) {
      setCurrentTaskIndex(currentTaskIndex + 1);
      setSelectedLeaf(null);
      setStartTime(Date.now()); // Reset start time for next task
      setTaskPath([]); // Reset path
      setUsedBack(false); // Reset back usage
      const rootNode = treeData[0]; // Reset to root node
      setBreadcrumbs([{ id: rootNode.id, name: rootNode.name }]);
    } else {
      completeTasks(); // Complete all tasks when the last one is confirmed
    }
  };

  const confirmSelection = () => {
    if (selectedLeaf) {
      const endTime = Date.now();
      const timeTaken = Math.round((endTime - startTime) / 1000); // Time in seconds, rounded to the nearest integer

      // Determine success
      const currentTask = tasks[currentTaskIndex];
      const isSuccess = currentTask.successLocations.includes(selectedLeaf.id);

      // Store task data
      const newTaskData = {
        id: currentTask.id,
        timer: timeTaken,
        direct: !usedBack,
        success: isSuccess,
        expected: currentTask.successLocations,
        path: taskPath
      };

      setTaskData(prev => [...prev, newTaskData]); // Add to task data array

      if (currentTaskIndex < tasks.length - 1) {
        setCurrentTaskIndex(currentTaskIndex + 1);
        setSelectedLeaf(null);
        setStartTime(Date.now()); // Reset start time for next task
        setTaskPath([]); // Reset path
        setUsedBack(false); // Reset back usage
        const rootNode = treeData[0]; // Reset to root node
        setBreadcrumbs([{ id: rootNode.id, name: rootNode.name }]);
      } else {
        completeTasks(); // Complete all tasks when the last one is confirmed
      }
    }
  };

  const handleSendData = async () => {
    const testData = {
      projectId,
      respondentId,
      taskData,
      completionCode: Math.random().toString(36).substring(2, 7)
    };
    setCompletionCode(testData.completionCode);

    try {
      const response = await axios.post(`${API_url}/test-response`, testData);
      console.log(response.data.message); // Display a success message from the server
      console.log('Response:', response.data);
    }
    catch (error) {
      console.error('Error sending test data:', error);
      console.log('Failed to send test data');
    }
  };

  const handleBackClick = () => {
    if (breadcrumbs.length > 1) {
      breadcrumbs.pop();
      setUsedBack(true); // User has used the back button
      const parentNode = breadcrumbs[breadcrumbs.length - 1];
      setSelectedLeaf(null);
      setBreadcrumbs([...breadcrumbs]);
      setTaskPath(prev => [...prev, parentNode.id]); // Add node to path
    }
  };

  const displayWithLineBreaks = (text) => {
    // Return null or an empty element if text is undefined or null
    if (text == null) { // Loose equality to check for both undefined and null
      return null; // or <span>No content</span> or similar
    }

    const safeText = String(text);

    return safeText.split('\n').map((line, index, array) => (
      <React.Fragment key={index}>
        {line}{index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div className="collect-container">
      {loading ? (
        <div className="spinner-container">
          Loading... <FaSpinner className="spinner" />
        </div>
      ) : (
        <React.Fragment>
          {!taskStarted ? (
            <div className="welcome-container">
              <div className="welcome-message">
                <b>Welcome</b>
                <br />
                {displayWithLineBreaks(welcomeMessage) || 'Text'}
              </div>
              <button className="start-button" onClick={startTasks}>Begin</button>
            </div>
          ) : taskCompleted ? (
            <div className="welcome-container">
              <div className="welcome-message">
                {settings?.settingsData.completionText || 'Thank you for completing the tasks'}
                <br></br><br></br>Your completion code is: <b>{completionCode}</b>
              </div>
            </div>
          ) : (
            <div>
              <div className='instructions'>
                <b>Task {currentTaskIndex + 1}</b>
                <br />
                {tasks[currentTaskIndex].description}
              </div>
              <div className="breadcrumbs">
                {breadcrumbs.map(b => b.name).join(' > ')}
                {!selectedLeaf && ' >'}
                <div className='button-container'>
                  {breadcrumbs.length > 1 && (
                    <button className="back-button" onClick={handleBackClick}>
                      <FaAngleLeft className='back-icon' /> Back
                    </button>
                  )}
                  {treeData.length && treeData.find(n => n.id === breadcrumbs[breadcrumbs.length - 1].id).children.map(childId => {
                    const childNode = treeData.find(n => n.id === childId);
                    return (
                      <button className="button-menu" key={childId} onClick={() => handleNodeClick(childNode)}>
                        {childNode.name}
                      </button>
                    );
                  })}
                  {selectedLeaf && (
                    <button className="confirm-button">
                      {`${breadcrumbs[breadcrumbs.length - 1].name}`}
                    </button>
                  )}
                </div>
              </div>
              {selectedLeaf && (
                <div className='button-container'>
                  <button className="submit-button" onClick={confirmSelection}>
                    Confirm <FaCheck />
                  </button>
                </div>
              )}
              {tasks[currentTaskIndex].allowSkipping && skippingAllowed && !selectedLeaf && (
                <div className='button-container'>
                  <button className="skip-button" onClick={skipTask}>
                    Skip Task
                  </button>
                </div>
              )}
            </div>

          )}
          {/* "Powered by" image and text */}
          <div className="powered-by-container">
            <span className="powered-by-text">Powered by</span>
            <img src={require("./img/treely-removebg.png")} alt="Logo" className="powered-by-image" />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Collect;
