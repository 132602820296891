import React, { useState } from 'react';
import TreeBuilder from './TreeBuilder';
import TaskManager from './TaskManager'; 
import Projects from './Projects'; 
import Settings from './Settings'; 
import Results from './Results'; 
import './TabbedLayout.css';
import { useAuth0 } from '@auth0/auth0-react';
import SplashPage from './SplashPage';

function TabbedLayout() {
  const { isAuthenticated } = useAuth0();
  const [activeTab, setActiveTab] = useState('project');
  const [newProjectId, setNewProjectId] = useState(null); // State to hold the new project ID
  const [newProjectName, setNewProjectName] = useState(null); // State to hold the new project name
  const [newProjectUrl, setNewProjectUrl] = useState(null); // State to hold the new project url


  const handleTabChange = tab => {
    setActiveTab(tab);
  };
  
  const switchActiveTab = (projectId, projectName, tab, projectUrl) => {
    // Set the new project ID and name in state
    console.log('Project ID:', projectId); // Check the output here
    console.log('Project Name:', projectName); // And here
    console.log('Project URL:', projectUrl); // And here
    setNewProjectId(projectId);
    setNewProjectName(projectName);
    setNewProjectUrl(projectUrl);
    setActiveTab(tab);
  };
  


  return (
    <div className="tabbed-layout">
      {isAuthenticated ? (
      <>
      <div className="tabbed-navigation">
      	<button className={activeTab === 'project' ? 'active' : ''} onClick={() => handleTabChange('project')}>1. Select Project</button>
        <button className={activeTab === 'tree' ? 'active' : ''} onClick={() => handleTabChange('tree')} disabled={activeTab === 'project'}>2. Build Tree</button>
        <button className={activeTab === 'tasks' ? 'active' : ''} onClick={() => handleTabChange('tasks')} disabled={activeTab === 'project'}>3. Create Tasks</button>
        <button className={activeTab === 'setup' ? 'active' : ''} onClick={() => handleTabChange('setup')} disabled={activeTab === 'project'}>4. Setup and Launch</button>
        <button className={activeTab === 'analyse' ? 'active' : ''} onClick={() => handleTabChange('analyse')} disabled={activeTab === 'project'}>5. Analyse Results</button>
      </div>
      <div className="tabbed-content">
      	{activeTab === 'project' && <Projects switchActiveTab={switchActiveTab} />} {/* Pass switchActiveTabToTree as a prop */}   
        {activeTab === 'tree' && <TreeBuilder switchActiveTab={switchActiveTab} projectId={newProjectId} projectName={newProjectName} projectUrl={newProjectUrl}/>} {/* Pass project ID, url and name as props */}
        {activeTab === 'tasks' && <TaskManager projectId={newProjectId} projectName={newProjectName} switchActiveTab={switchActiveTab} projectUrl={newProjectUrl}/>}
        {activeTab === 'setup' && <Settings projectId={newProjectId} projectName={newProjectName} switchActiveTab={switchActiveTab} projectUrl={newProjectUrl} /> }
        {activeTab === 'analyse' && <Results projectId={newProjectId} projectName={newProjectName} switchActiveTab={switchActiveTab} projectUrl={newProjectUrl} /> }
      </div>
      </>
      ) : (
      <>  
        <SplashPage />
      </>
      )}
    </div>
  );
}

export default TabbedLayout;
