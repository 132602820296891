import React from 'react';

const StackedBarChart = ({ section1, section2, section3, section4 }) => {
  const total = section1 + section2 + section3 + section4;
  const section1Width = `${(section1 / total) * 100}%`;
  const section2Width = `${(section2 / total) * 100}%`;
  const section3Width = `${(section3 / total) * 100}%`;
  const section4Width = `${(section4 / total) * 100}%`;

  return (
    <div className='graph-table' style={{ display: 'flex', height: '30px', width: '100%', backgroundColor: '#f0f0f0' }}>
      <div className='direct-success-bar' style={{ width: section1Width, height: '100%' }}>{Math.round(section1)}%</div>
      <div className='indirect-success-bar' style={{ width: section2Width, height: '100%' }}>{Math.round(section2)}%</div>
      <div className='direct-fail-bar' style={{ width: section3Width, height: '100%' }}>{Math.round(section3)}%</div>
      <div className='indirect-fail-bar' style={{ width: section4Width, height: '100%' }}>{Math.round(section4)}%</div>
    </div>
  );
};

export default StackedBarChart;
