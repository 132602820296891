import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import './TreeBuilder.css';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import { FaSpinner } from 'react-icons/fa';
import InfoIcon from './InfoIcon'; 
import { toast } from 'react-toastify';

function TreeBuilder({ projectId, projectName, switchActiveTab, projectUrl }) { // Receive projectId and projectName as props
  const [tree, setTree] = useState([{ id: 1, name: 'Root', children: [] }]);
  const [newItemName, setNewItemName] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteNodeModal, setDeleteNodeModal] = useState({ show: false, nodeId: null });
  const [treeJson, setTreeJson] = useState(null);
  const { isAuthenticated, loginWithRedirect, logout, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [blankNodeIds, setBlankNodeIds] = useState([]);
  
  const API_url = process.env.REACT_APP_API_URL;
  
  useEffect(() => {
    if (projectId) {
      fetchTreeData();
    }
  }, [projectId]);

  const fetchTreeData = async () => {
    setLoading(true); // Start loading
    try {
      const options = { authorizationParams: { audience: process.env.REACT_APP_AUDIENCE } };
      const token = await getAccessTokenSilently(options);
      const response = await axios.get(`${API_url}/tree`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { treeId: projectId }
      });
      const { treeData } = response.data;
      const unflattenedTree = unflattenTree(treeData);
      setTree(unflattenedTree || []);
    } catch (error) {
      console.error('Error fetching tree data:', error);
    } finally {
      setLoading(false); // Stop loading whether or not there was an error
    }
  };
  
  const handleAddItem = parentId => {
    const newItem = { id: Date.now(), name: newItemName, children: [] };
    const updatedTree = [...tree];
    const parentNode = findNodeById(updatedTree, parentId);
    if (parentNode) {
      parentNode.children.push(newItem);
      setTree(updatedTree);
      setNewItemName('');
    }
  };

  const handleDeleteItem = (parentId, itemId) => {
    const nodeToDelete = findNodeById(tree, itemId);
    if (nodeToDelete.children.length > 0) {
      setDeleteNodeModal({ show: true, nodeId: itemId });
    } else {
      const updatedTree = deleteNode(tree, parentId, itemId);
      setTree(updatedTree);
    }
  };

  const handleConfirmDelete = () => {
    const updatedTree = deleteNode(tree, findParentId(tree, deleteNodeModal.nodeId), deleteNodeModal.nodeId);
    setTree(updatedTree);
    setDeleteNodeModal({ show: false, nodeId: null });
  };

  const handleCancelDelete = () => {
    setDeleteNodeModal({ show: false, nodeId: null });
  };

  const deleteNode = (nodes, parentId, itemId) => {
    return nodes.map(node => {
      if (node.id === parentId) {
        node.children = node.children.filter(child => child.id !== itemId);
      } else {
        node.children = deleteNode(node.children, parentId, itemId);
      }
      return node;
    }).filter(node => node.id !== itemId);
  };

  const toastPop = (toastMessage) => {
    toast(`${toastMessage}`);
  };

  const handleRenameItem = (id, newName) => {
    const updatedTree = [...tree];
    const nodeToUpdate = findNodeById(updatedTree, id);
    if (nodeToUpdate) {
      nodeToUpdate.name = newName;
      setTree(updatedTree);
    }
  };

  const findNodeById = (nodes, id) => {
    for (let node of nodes) {
      if (node.id === id) return node;
      const childNode = findNodeById(node.children, id);
      if (childNode) return childNode;
    }
    return null;
  };

  const countChildren = node => {
    return node.children.length;
  };

  const renderTree = (nodes, depth = 0, parentName = "") => {

    return (
      <div className="tree">
        {nodes.map((node, index) => {
          const nodeName = parentName ? `${parentName}.${index + 1}` : `${index + 1}`;
          const isRootNode = depth === 0 && index === 0;
          console.log(blankNodeIds);
          return (
            <div key={node.id} className={isRootNode ? 'root-node' : 'tree-node'} style={{ marginLeft: isRootNode ? `0px` : `${60}px` }}>
              <div className="level-label">L{depth}</div><input
                type="text"
                placeholder={node.name || nodeName}
                onChange={(e) => handleRenameItem(node.id, e.target.value)}
                className={`${blankNodeIds.includes(node.id) ? 'blank-node' : 'node-input'}`}
                defaultValue={node.name}
              />
              <span>({countChildren(node)})</span>
              <button onClick={() => handleAddItem(node.id)}><FaPlus className="plus-icon"/></button>
              {!isRootNode && <button onClick={() => handleDeleteItem(findParentId(tree, node.id), node.id)}><FaTrash className="trash-icon"/></button>}
              {node.children.length > 0 && renderTree(node.children, depth + 1, nodeName)}
            </div>
          );
        })}
      </div>
    );
  };
  
  const findParentId = (nodes, childId) => {
    for (let node of nodes) {
      if (node.children.some(child => child.id === childId)) {
        return node.id;
      }
      const parentId = findParentId(node.children, childId);
      if (parentId !== null) return parentId;
    }
    return null;
  };

  const flattenTree = (nodes) => {
    const flattenedNodes = [];
    const traverse = (node) => {
      const { id, name, children } = node;
      const childrenIDs= children.map(child => child.id);
      flattenedNodes.push({ id, name, children: childrenIDs });
      children.forEach(child => traverse(child));
    };
    nodes.forEach(node => traverse(node));
    return flattenedNodes;
  };
  
  
function unflattenTree(flatTree) {
    const nodeMap = new Map();
    let root = null;

    // Create a map of all nodes and initialize them with empty children arrays
    flatTree.forEach(node => {
        if (!nodeMap.has(node.id)) {
            nodeMap.set(node.id, { ...node, children: [] });
        }
    });

    // Set children for each node
    flatTree.forEach(node => {
        const currentNode = nodeMap.get(node.id);
        node.children.forEach(childId => {
            const childNode = nodeMap.get(childId);
            if (childNode) {
                currentNode.children.push(childNode);
            }
        });
    });

    // Identify the root node (a node not referenced as a child)
    const allChildIds = new Set(flatTree.flatMap(node => node.children));
    nodeMap.forEach(node => {
        if (!allChildIds.has(node.id)) {
            root = node;
        }
    });

    return root ? [root] : []; // return the root node in an array if found
}




  const saveTreeData = async () => {
  // Check for blank node names
  const hasBlankNodeName = (nodes) => {
    let blankIds = [];
    nodes.forEach(node => {
      if (node.name.trim() === '') {
        blankIds.push(node.id);
      }
      if (node.children && node.children.length > 0) {
        blankIds = [...blankIds, ...hasBlankNodeName(node.children)];
      }
    });
    return blankIds;
  };

  const blankIds = hasBlankNodeName(tree);
  if (blankIds.length > 0) {
    setBlankNodeIds(blankIds);
    toast.error('Error saving: Please name all nodes before saving');
    return;
  }

  setBlankNodeIds([]); // Clear previous blank node IDs

  try {
    // Parse and flatten the tree data
    const updatedTree = JSON.parse(JSON.stringify(tree));
    const flatTree = flattenTree(updatedTree);
    console.log('Saving flat tree data:', flatTree);
    
    // Define the treeId (you need to implement this function)
    const treeId = projectId;
    
    // Add the treeId to the flatTree object
    const treeDataWithId = { treeId, treeData: flatTree };
    
    const options = { authorizationParams: { audience: process.env.REACT_APP_AUDIENCE } };
        //console.log("Options:", options);
    const token = await getAccessTokenSilently(options);

    // Send the data to the backend
    const response = await axios.post(`${API_url}/tree`, treeDataWithId, { headers: { Authorization: `Bearer ${token}` } });
    setTreeJson(JSON.stringify(response.data, null, 2));
    toastPop('Tree data saved');
    switchActiveTab(projectId, projectName, 'tasks', projectUrl)
    
  } catch (error) {
    console.log("ERROR", error)
    toast.error('Error saving tree data:');
  }
};


if (loading) {
  return <div className="spinner-container">
      Searching for existing tree data... <FaSpinner className="spinner" />
    </div>; // Render a loading message
}
else {
    return (
      <div className="saas-tree">
        <h2>Build Tree - {projectName} <InfoIcon /></h2> {/* Project name as header */}
        <div className="container">
            <div>
              {renderTree(tree)}
              <div className="vertical-spacer"></div>
              {deleteNodeModal.show && (
                <div className="modal">
                  <div className="modal-content">
                    <p>Are you sure you want to delete this node and its children?</p>
                    <button onClick={handleConfirmDelete}>Yes</button>
                    <button onClick={handleCancelDelete}>No</button>
                  </div>
                </div>
              )}
              <div className="fixed-bar">
                <button className="save-button" onClick={saveTreeData}>Save Tree (Proceed to Tasks)</button>
              </div>
            </div>
        </div>
      </div>
    );
  }
}



export default TreeBuilder;
