import React, { useState, useEffect, useRef } from 'react';
import './App.css'; // Import CSS file for styling
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { format } from 'date-fns';
import { FaSpinner, FaEllipsisV, FaArrowUpRightFromSquare} from 'react-icons/fa';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';

const API_url = process.env.REACT_APP_API_URL;

function Projects({ switchActiveTab }) {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [showModal, setShowModal] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [projectUrl, setProjectUrl] = useState('');
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDropdown, setShowDropdown] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const dropdownRef = useRef(null);

  useEffect(() => {
    console.log("Fetching projects")

    
    const fetchProjects = async () => {
      if (isAuthenticated && user) {
        try {
          const options = { authorizationParams: { audience: process.env.REACT_APP_AUDIENCE } };
          const token = await getAccessTokenSilently(options); //Modify code to pass the token on rather than calling getTokenSilelntly each time API call is made
          const sub = user.sub;
          const response = await axios.get(`${API_url}/projects?sub=${sub}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const parsedProjects = response.data.filter(project => (
            project.id && project.name && project.date
          ));
          setProjects(parsedProjects);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching projects:', error);
          toast.error('Error fetching projects');
          setLoading(false);
        }
      }
    };
    fetchProjects();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(null);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sanitizeText = (text) => {
    // Configure DOMPurify to allow <br> and <p> tags
    const cleanText = DOMPurify.sanitize(text, {
        ALLOWED_TAGS: [], // Allow <br> and <p> tags
        ALLOWED_ATTR: [] // No attributes allowed to keep it simple and secure
    });

    return cleanText;
};


  const addProject = async () => {
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (!alphanumericRegex.test(projectName.trim())) {
      alert('Project name can only contain alphanumeric characters and spaces.');
      return;
    }
    const collectUrl = Math.random().toString(36).substring(2, 12);
    if (projectName.trim() !== '' && !projects.find(project => project.name === projectName.trim())) {
      const newProject = {
        id: `${user.sub}.${Date.now()}`,
        name: sanitizeText(projectName.trim()),
        date: new Date(),
        status: 0,
        collectUrl: collectUrl,
      };
      setProjects(projects => [...projects, newProject]);
      setShowModal(false);
      setProjectName('');
      await sendToDatabase();
      toastPop(`${newProject.name} created`);
      switchActiveTab(newProject.id, newProject.name, 'tree', newProject.collectUrl);
    }
  };

  const toastPop = (toastMessage) => {
    toast(`${toastMessage}`);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const renderHeader = (column, label) => {
    const sortedArrow = sortBy === column ? (sortOrder === 'asc' ? '↓' : '↑') : '';
    return (
      <th key={column} onClick={() => handleSort(column)}>
        {label} {sortedArrow}
      </th>
    );
  };

  const renderStatusTag = (status) => {
    let className = '';
    let tagText = '';
    switch (status) {
      case 0: className = 'draft'; tagText = 'Draft'; break;
      case 1: className = 'live'; tagText = 'Live'; break;
      case 2: className = 'closed'; tagText = 'Closed'; break;
      default: className = 'draft'; tagText = 'Draft'; break;
    }
    return (
      <div className={`status-pill ${className}`}>
        {tagText}
      </div>
    );
  };

  //NEEDS FIXING
  const sortedProjects = () => {
    if (!sortBy) return projects;
    return [...projects].sort((a, b) => {
      if (sortBy === 'dateCreated') {
        const dateA = new Date(a[sortBy]);
        const dateB = new Date(b[sortBy]);
        return sortOrder === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      } else if (sortBy === 'status') {
        return sortOrder === 'asc' ? a[sortBy] - b[sortBy] : b[sortBy] - a[sortBy];
      } else {
        return sortOrder === 'asc' ? a[sortBy].localeCompare(b[sortBy]) : b[sortBy].localeCompare(a[sortBy]);
      }
    });
  };

  const sendToDatabase = async () => {
    if (isAuthenticated && user) {
      try {
        const { sub, email } = user;
        const options = { authorizationParams: { audience: process.env.REACT_APP_AUDIENCE } };
        //console.log("Options:", options);
        const token = await getAccessTokenSilently(options);
        //console.log("Token:", token);
        setProjects(projects => {
          const userData = { sub, email, projects };
          axios.post(`${API_url}/user`, userData, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => console.log(response.data))
            .catch(error => console.error(error));
          return projects;
        });
      } catch (error) {
        console.error(error);
        toast.error('Error saving projects');
      }
    }
  };

  const handleProjectClick = (projectId, projectName, projectUrl) => {
    console.log(`Project with ID ${projectId} clicked.`);
    switchActiveTab(projectId, projectName, 'tree', projectUrl);
  };

  const handleProjectAction = (action, projectId, projectName, projectUrl) => {
    switch (action) {
      case 'view':
        handleProjectClick(projectId, projectName, projectUrl);
        break;
      case 'duplicate':
        // Implementation for duplicating a project
        break;
      case 'delete':
        // Implementation for deleting a project
        handleDeleteProject(projectId);
        break;
      default:
        console.log('Unknown action:', action);
        return; // Optionally return to stop further execution
    }
    console.log(action, projectId); // Log the action here, after it's confirmed
  };

  const handleDeleteProject = async (projectId) => {
    try {
      const { sub } = user;  // Assumed to be derived from the Auth0 token or user context
      const options = { authorizationParams: { audience: process.env.REACT_APP_AUDIENCE } };
      const token = await getAccessTokenSilently(options);

      // Include the sub in the query parameters
      const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { sub } // Pass the user's sub as a query parameter
      };

      await axios.delete(`${API_url}/projects/${projectId}`, config);

      // Update the local state to reflect the deletion without needing to refetch the entire list
      setProjects(projects.filter(project => project.id !== projectId));

      toastPop(`Project deleted`);
    } catch (error) {
      toastPop.error(`Error deleting project`);
      console.error('Error deleting project:', error);
    }
  };


  const toggleDropdown = (projectId) => {
    setShowDropdown(showDropdown === projectId ? null : projectId);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm');
  };

  return (
    <div>
      {loading ? (
        <div className="spinner-container">
          Searching for your projects... <FaSpinner className="spinner" />
        </div>
      ) : (
        <React.Fragment>
          <button className="secondary_button" onClick={() => setShowModal(true)}>+ Create New Project</button>
          <table className="project-table">
            <thead>
              <tr>
                {renderHeader('name', 'Project Name')}
                {renderHeader('dateCreated', 'Date Created')}
                {renderHeader('status', 'Status')}
                {renderHeader('responses', 'Responses')}
                {renderHeader('actions', 'Actions')}
              </tr>
            </thead>
            <tbody>
              {sortedProjects().map(project => (
                <tr key={project.id}>
                  <td>
                    <button className="tertiary_button" onClick={() => handleProjectClick(project.id, project.name, project.collectUrl)}>
                      {project.name}
                    </button>
                  </td>
                  <td>{formatDate(project.date)}</td>
                  <td>{renderStatusTag(project.status)}</td>
                  <td>{project.responses}</td>
                  <td>
                    <FaEllipsisV onClick={() => toggleDropdown(project.id)} />
                    {showDropdown === project.id && (
                      <div className="dropdown-menu" ref={dropdownRef}>
                        <button onClick={() => handleProjectAction('view', project.id, project.name, project.collectUrl)}>View</button>
                        <button onClick={() => handleProjectAction('duplicate', project.id, project.name)}>Duplicate</button>
                        <button onClick={() => handleProjectAction('rename', project.id, project.name)}>Rename</button>
                        <button onClick={() => handleProjectAction('delete', project.id, project.name)}>Delete</button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {projects.length === 0 && <p>No projects available.</p>}
          {showModal && (
            <div className="modal">
              <div className="modal-content">
                  <span className="close" onClick={() => setShowModal(false)}>&times;</span>
                  <h2>Create New Project</h2>
                  <form onSubmit={addProject}>
                    <div className="input-group">
                      <label>Project Name:</label>
                      <input
                        type="text"
                        value={projectName}
                        onChange={e => {
                          const value = e.target.value;
                          // Regular expression that matches anything that is not a number, letter, or space
                          const regex = /[^a-z\d\s]/gi;
                          // Replace characters not matching the allowed set with an empty string
                          setProjectName(value.replace(regex, ''));
                        }}
                      />
                    </div>
                    <div className="button-container">
                      <button type="button" className="cancel-button" onClick={() => setShowModal(false)}>Cancel</button>
                      <button className="blue-button" type="submit">Create Project</button>
                    </div>
                </form>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default Projects;
