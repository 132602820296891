import React, { useState, useEffect } from 'react';
import './App.css';
import { FaPlus, FaTrash, FaTimes} from 'react-icons/fa';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import { FaSpinner, FaBullseye } from 'react-icons/fa';

function TaskManager({ projectId, projectName, switchActiveTab, projectUrl}) {
  const [tasks, setTasks] = useState([]);
  const [leafPaths, setLeafPaths] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (projectId) {
      fetchLeafPaths();
      fetchTasks();
    }
  }, [projectId]);

  const fetchLeafPaths = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/leaf-paths`, {
        params: { treeId: projectId }
      });
      setLeafPaths(response.data);
    } catch (error) {
      console.error('Error fetching leaf paths:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/tasks`, {
        params: { projectId: projectId }
      });
      setTasks(response.data); // Assuming the response contains an array of tasks
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const handleAddTask = () => {
    const newTask = {
      id: `${projectId}.${tasks.length + 1}`,
      description: '',
      successLocations: [''],
      allowSkipping: false
    };
    setTasks([...tasks, newTask]);
  };

  const handleAddSuccessLocation = (taskId) => {
    const updatedTasks = tasks.map(task => {
      if (task.id === taskId) {
        return {...task, successLocations: [...task.successLocations, '']};
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  const handleRemoveSuccessLocation = (taskId, index) => {
    const updatedTasks = tasks.map(task => {
      if (task.id === taskId) {
        const newSuccessLocations = task.successLocations.filter((_, idx) => idx !== index);
        return {...task, successLocations: newSuccessLocations};
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  const handleSuccessLocationChange = (taskId, index, event) => {
    const updatedTasks = tasks.map(task => {
      if (task.id === taskId) {
        const newSuccessLocations = task.successLocations.map((location, idx) => {
          if (idx === index) return event.target.value;
          return location;
        });
        return {...task, successLocations: newSuccessLocations};
      }
      return task;
    });
    setTasks(updatedTasks);
  };

  const handleDescriptionChange = (taskId, event) => {
    const updatedTasks = tasks.map(task => task.id === taskId ? { ...task, description: event.target.value } : task);
    setTasks(updatedTasks);
  };

  const handleAllowSkippingChange = (taskId, event) => {
    const updatedTasks = tasks.map(task => task.id === taskId ? { ...task, allowSkipping: event.target.checked } : task);
    setTasks(updatedTasks);
  };

  const handleDeleteTask = (taskId) => {
    const updatedTasks = tasks.filter(task => task.id !== taskId)
                              .map((task, index) => ({ ...task, id: index + 1 }));
    setTasks(updatedTasks);
  };

  const toastPop = (toastMessage) => {
    toast(`${toastMessage}`);
  };

  const handleSaveTasks = async () => {
    try {
      console.log("Saving tasks", tasks);
      const options = { authorizationParams: { audience: process.env.REACT_APP_AUDIENCE } };
      const token = await getAccessTokenSilently(options);
      console.log(token); 
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/save-tasks`, 
        { projectId: projectId, tasks: tasks },  // This is the data payload
        { headers: { Authorization: `Bearer ${token}` } } // Headers should be passed like this
      );
      console.log('Tasks saved successfully:', response.data);
      toastPop(`Tasks saved`);
      console.log("Passing this to tabswitcher", projectId);
      switchActiveTab(projectId, projectName, 'setup', projectUrl);
    } catch (error) {
      console.error('Error saving tasks:', error);
    }
  };

  if (loading) {
    return <div className="spinner-container">
        Searching for existing task data... <FaSpinner className="spinner" />
      </div>; // Render a loading message
  }
  else {
    return (
      <div className="task-manager">
        <h2>Tasks - {projectName}</h2>
        {tasks.map((task, index) => (
          <div key={task.id} className="task">
            <h3>Task {index + 1}</h3>
            <div className="task-content">
              <div className="task-description">
                Task Instructions:
                <textarea
                  placeholder={`Task ${index + 1} instructions (e.g. Imagine that you want to...  ...where would you go to do that?)`}
                  value={task.description}
                  onChange={(e) => handleDescriptionChange(task.id, e)}
                />
                <div className="success-locations-label">
        <FaBullseye />
        Success Location(s):
      </div>
                {task.successLocations.map((location, idx) => (
                  <div key={idx} className="success-location">
    <select
      className="custom-select"
      value={location}
      onChange={(e) => handleSuccessLocationChange(task.id, idx, e)}
    >
      <option value="" disabled> </option>
      {leafPaths.map(leaf => (
        <option key={leaf.leafId} value={leaf.leafId}>
          {leaf.pathString}
        </option>
      ))}
    </select>
    {idx !== 0 && (
      <button onClick={() => handleRemoveSuccessLocation(task.id, idx)} className="button-remove">
        <FaTimes />
      </button>
    )}
  </div>
                ))}
                <button onClick={() => handleAddSuccessLocation(task.id)} className="button-task">+</button>
              </div>
              <div className="task-controls">
                <label>
                  Allow Skip
                  <input
                    type="checkbox"
                    checked={task.allowSkipping}
                    onChange={(e) => handleAllowSkippingChange(task.id, e)}
                  />
                </label>
                <button onClick={() => handleDeleteTask(task.id)} className="button-task">Delete Task <FaTrash className="trash-icon"/></button>
              </div>
            </div>
          </div>
        ))}
        <button onClick={handleAddTask} className="secondary_button">+ Add Task</button>
        <div className="divider"/>
        <div className="vertical-spacer"></div>
        <div className="fixed-bar">
        <button onClick={handleSaveTasks} className="save-button">Save Tasks (Proceed to Setup)</button>
              </div>
        
      </div>
    );
  }
}

export default TaskManager;
